<template>
  <v-app>
    <app-loader v-if="loader"></app-loader>

    <app-header  v-if="this.$vuetify.breakpoint.width > 1200 && token" ></app-header>
    <app-aside-bar-movil  v-if="this.$vuetify.breakpoint.width <= 1200 && visibilidadMenu()" :menu="menu" ></app-aside-bar-movil>
    <app-aside-bar  v-if="this.$vuetify.breakpoint.width > 1200 && visibilidadMenu()" :menu="menu"
    ></app-aside-bar>
    <v-main class="white">
      <div >
        <router-view :key="$route.fullPath" />
        <app-foorter></app-foorter>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import jwtDecode from "jwt-decode";
import AppFoorter from "@/components/AppFooter.vue";

export default {
  name: "layout",
  components: {AppFoorter},
  data: () => ({
    rura_foto: null,
    foto: null,
    menus: [],
  }),
  methods: {
    ...mapMutations(["setUserInfo"]),
    ...mapActions('utils', ['getMenu']),
    async getFoto() {

         const params = {
             tipo_consulta: 'F',
             tipo_documento: 1,
             id_persona: this.userInfo.id,
         }
         try {
         let response = await this.services.datosPersona.getDocumentoPersona(params);

          if (response.status == 200) {

                    this.foto = response?.data.archivo;
          } else {
                    this.foto = require(`@/assets/img/default_user.png`);
          }
        } catch (error) {
          this.temporalAlert({
                        show: false,
                        message: 'No se ha establecido una foto de perfil',
                        type: "error",
                    });
          this.foto = require(`@/assets/img/default_user.png`);

        }
     },
     setUser(){


      const data = {
            nombres: this.userInfo.nombres,
            apellidos:this.userInfo.apellidos,
            ruta_foto: this.foto,
            id_persona: this.userInfo.id,
            telefono: this.userInfo.telefono,
            email_personal: this.userInfo.email_personal,
            fecha_nacimiento: this.userInfo.fecha_nacimiento,
            id_sexo: this.userInfo.id_sexo,
            paso_formulario: this.userInfo.paso_formulario,
            numero_documento: this.userInfo.numero,
        }
        this.setUserInfo(data)
     },
    visibilidadMenu(){
        const token = localStorage.getItem("token");
        if(!token){
          return false;
        }
        return true;
      }
  },
  computed: {
    ...mapState({
      userInfo: "userInfo",
      token: "token",
    }),
    ...mapState("utils", ["loader","menu","roles"]),
  },
  async created() {
    this.getMenu();

    const userToken = this.token || localStorage.getItem('token');
    const pasoForm = localStorage.getItem('paso_formulario')
    if (userToken && pasoForm === '0') {
      const userDecode = jwtDecode(userToken);
      this.setUserInfo(userDecode.persona);
      // Filtrar objetos con visible igual a true
      // this.menus = this.menus.filter(function(objeto) {
      //     return objeto.visible === true;
      // });

      if (this.userInfo.ruta_foto){
        await this.getFoto();
      } else {
        this.foto = require(`@/assets/img/default_user.png`);
      }
      this.setUser();


    } else {
      const urlExcluidas = [
                            'oferta-empleo',
                           'empleo-detalle'
                          ];
      if (!urlExcluidas.includes(this.$router.currentRoute.name) && pasoForm === '0' ){
        this.$router.push("/login").catch((e) => { });
      // }else if(pasoForm !== '0' || pasoForm === null){
      //   this.$router.push("/login").catch((e) => { });
      }else if(pasoForm === '1' ){
        this.$router.push("/login").catch((e) => { });
      }

    }






  },
};
</script>

<style scoped>
</style>
